import React, { ReactNode, Suspense } from "react";
import { Loader } from "./Loader/Loader";

export function SuspenseWrp({
  children,
  fallback,
}: {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}) {
  let fb: ReactNode = <Loader className="w-[50px] h-[50px]" />;
  if (fallback) {
    fb = fallback;
  }

  return <Suspense fallback={fb}>{children}</Suspense>;
}
