import { ImageType } from "./utility";

// ImageType type guard
export function isImageType(data: any | ImageType): data is ImageType {
  if (!data) return false;
  const img = data as ImageType;
  return !!(img["dataUrl"] || img.dataURL);
}

// Image file type guard
export function isImageFileType(data: any | ImageType): data is ImageType {
  if (!data) return false;
  const img = data as ImageType;
  return !!((img["data_url"] || img["dataUrl"]) && img.file);
}
