import React from "react";
import { classnames } from "utils/common";
import { ILoaderProps, Loader } from "components/Loader/Loader";

export interface IButtonProps
  extends Partial<React.ButtonHTMLAttributes<HTMLButtonElement>> {
  mode?: "primary" | "white";
  loading?: boolean;
  classNames?: { container?: string; spinner?: string };
  innerRef?: React.RefObject<HTMLButtonElement>;
  spinnerType?: ILoaderProps["type"];
}

/**
 * Primary UI component for user interaction
 */

export function Button({
  mode = "primary",
  children,
  loading,
  className,
  classNames,
  innerRef,
  spinnerType,
  ...props
}: IButtonProps) {
  return (
    <button
      ref={innerRef}
      type="button"
      className={classnames(
        { "bg-[#36382E] text-white": mode === "primary" },
        { " text-[#36382E]  ": mode === "white" },
        " rounded-[28px]",
        "disabled:bg-[#D5D5D5]",
        "disabled:text-white",

        "font-semibold",
        "py-[12px]",
        "px-6",
        "flex",
        "justify-center",
        "relative",
        "cursor-pointer",
        "items-center",
        " hover:opacity-80 ",
        className
      )}
      {...props}
    >
      <div
        className={classnames(
          "relative flex justify-center items-center",
          classNames?.container
        )}
      >
        {loading ? (
          <Loader
            className={classnames(" h-6", classNames?.spinner)}
            type={spinnerType}
          />
        ) : (
          ""
        )}
        {!loading && children}
      </div>
    </button>
  );
}
