import { classnames } from "utils/common";
import { ReactComponent as InformationMark } from "assets/img/information-mark.svg";
import { ReactComponent as Close } from "assets/img/close.svg";
import { ReactComponent as CloudDisabled } from "assets/img/cloud-disabled.svg";
import React from "react";
//@ts-ignore
import { deserialize } from "react-serialize";

export interface IProfileNotificationProps {
  className?: string;
  type: "warning" | "success" | "error";
  onClose?: () => void;
  children: React.ReactNode;
  showCloseButton?: boolean;
}

export function ProfileNotification({
  type,
  children,
  className,
  onClose,
  showCloseButton = true,
}: IProfileNotificationProps) {
  let toRender: any = "";
  const isValid = React.isValidElement(children);
  //there could be an issue with children cause we use recoil that save data to LocalStorage, so our ReactNode serializing to json, in this case we need to deserialize it
  if (isValid || typeof children === "string") {
    toRender = children;
  }

  if (!isValid && typeof children === "object") {
    const deserialized = deserialize(children);
    if (React.isValidElement(deserialized)) {
      toRender = deserialized;
    }
  }

  return (
    <div
      className={classnames(
        "flex flex-col py-[10px] px-[18px] z-10",
        {
          " bg-[#f0faf0]  border-b border-[#67CE67]": type === "success",
        },
        {
          " bg-[#fff5e5] border-b border-[#FF9900]": type === "warning",
        },
        {
          " bg-[#fcedee] border-b border-[#E34953]": type === "error",
        },
        className
      )}
    >
      <div className="flex items-center gap-4 justify-between">
        {type === "success" && <InformationMark className=" fill-[#67CE67]" />}
        {type === "warning" && <CloudDisabled className=" fill-[#FF9900]" />}
        {type === "error" && <InformationMark className=" fill-[#E34953]" />}
        <div className="flex flex-1 flex-wrap">{toRender}</div>
        {showCloseButton && (
          <Close
            className="cursor-pointer stroke-black shrink-0"
            onClick={onClose}
          />
        )}
      </div>
    </div>
  );
}
