import { useRegisterSW } from "virtual:pwa-register/react";
import { ProfileNotification } from "components/ProfileNotification/ProfileNotification";
import { Button } from "components/Button/Button";

const buildDate = "__DATE__";
// replaced dyanmicaly
const reloadSW = "__RELOAD_SW__";
const onRegisteredSw = (swUrl: string, r: any) => {
  console.log(`Service Worker at: ${swUrl}`, buildDate);
  // @ts-expect-error just ignore
  if (reloadSW === "true") {
    r &&
      setInterval(() => {
        console.log("Checking for sw update");
        r.update();
      }, 20000 /* 20s for testing purposes */);
  } else {
    console.log("SW Registered: " + r);
  }
};
const onRegisterError = (error: any) => {
  console.log("SW registration error", error);
};
const config = {
  onRegisteredSW(swUrl: string, r: any) {
    onRegisteredSw(swUrl, r);
  },
  onRegisterError(error: any) {
    onRegisterError(error);
  },
};

function ReloadPrompt() {
  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW(config);

  const close = () => {
    setOfflineReady(false);
    setNeedRefresh(false);
  };
  const showMessage = offlineReady || needRefresh;
  return (
    <>
      {showMessage && (
        <ProfileNotification onClose={close} type="warning">
          <div className="flex   w-full gap-4 ">
            <div className="text-sm">
              New content available, click on reload button to update.{" "}
            </div>
            <Button
              mode="white"
              className="px-0 py-0"
              onClick={() => updateServiceWorker(true)}
            >
              Reload
            </Button>
          </div>
        </ProfileNotification>
      )}
    </>
  );
}

export default ReloadPrompt;
