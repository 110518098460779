import { getAuth } from "firebase/auth";
import { queryClient } from "index";

import transform from "lodash/transform";
import isEqual from "lodash/isEqual";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import isEmpty from "lodash/isEmpty";
import axios from "services/axios";
//@ts-ignore
import emptyProfilePlaceholder from "assets/img/empty-profile-placeholder.svg?default";
import { isImageFileType, isImageType } from "types/guard";
import { MixedSchema } from "yup/lib/mixed";
import * as yup from "yup";
import { ImageType, ProfileNotificationType } from "types/utility";
import { StylesConfig } from "react-select";

import { Options, serialize } from "object-to-formdata";
import type {
  JSONObject,
  Message,
  Paginator,
  Participant,
} from "@twilio/conversations";

import {
  IAccount,
  IFullConversation,
  INotification,
  IReferralProgram,
  ISelectOption,
  ISocialNetwork,
  IUser,
} from "types/entities";
import { InfiniteData } from "@tanstack/react-query";
import { IAxiosResponseWithPagination } from "services/requests";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import type {
  CurrentChatType,
  ITwilioConversations,
  TwilioConversationsContextValueType,
} from "types/chats";

export function findChatAndConversationByConversationId(
  id: string,
  prev: TwilioConversationsContextValueType
) {
  const chat = Object.values(prev || {}).find((chat) => {
    return chat.conversations.find((i) => i.conversation.id === id);
  });
  const conversation = chat?.conversations.find(
    (i) => i.conversation.id === id
  );
  return { chat, conversation };
}

export function toFormData<T>(data: T, options: Options = {}) {
  return serialize(data, { indices: true, ...options });
}

async function uploadImage(dataUrl: string) {
  const image = new Image();
  image.src = dataUrl;

  return image;
}

export function filterEmptyObjectItems(values: { [key: string]: any }) {
  function internalClean(el: any) {
    return transform(
      el,
      function (result: { [key: string]: any }, value: any, key: string) {
        const isCollection = isObject(value);
        const cleaned = isCollection ? internalClean(value) : value;

        if (isCollection && isEmpty(cleaned)) {
          return;
        }

        isArray(result) ? result.push(cleaned) : (result[key] = cleaned);
      }
    );
  }

  return isObject(values) ? internalClean(values) : values;
}

//https://davidwells.io/snippets/get-difference-between-two-objects-javascript
/**
 * Find difference between two objects
 * @param  {object} origObj - Source object to compare newObj against
 * @param  {object} newObj  - New object with potential changes
 * @return {object} differences
 */
export function difference(
  origObj: { [key: string]: any },
  newObj: { [key: string]: any },
  selectorByKey?: (key: string | number | symbol) => boolean
) {
  function changes(
    newObj: { [key: string]: any },
    origObj: { [key: string]: any }
  ) {
    let arrayIndexCounter = 0;
    return transform(
      newObj,
      function (result: { [key: string]: any }, value, key) {
        if (!isEqual(value, origObj[key])) {
          const resultKey = isArray(origObj) ? arrayIndexCounter++ : key;
          if (selectorByKey && selectorByKey(resultKey)) {
            result[resultKey] =
              isObject(value) && isObject(origObj[key])
                ? changes(value, origObj[key])
                : value;
          }
          if (!selectorByKey) {
            result[resultKey] =
              isObject(value) && isObject(origObj[key])
                ? changes(value, origObj[key])
                : value;
          }
        }
      }
    );
  }
  return changes(newObj, origObj);
}

export function buildSortParams<T extends object>(param = "") {
  if (typeof param === "string") {
    const result = param?.match(/^-/);
    if (result) {
      const key = param.slice(1);
      return [key, "DESC"] as [keyof T, "DESC"];
    }
  }
  return [param, "ASC"] as [keyof T, "ASC"];
}

export async function signOut(cb?: () => void) {
  await getAuth().signOut();
  queryClient.removeQueries();
  queryClient.clear();
  localStorage.clear();
  window.history.pushState({}, document.title, window.location.pathname); //remove search params on signOut
  cb ? cb() : window.location.reload();
}

export function sortByTimeCallback(a: Date | string, b: Date | string) {
  if (typeof a === "string") {
    if (a && b) {
      if (new Date(a) < new Date(b)) {
        return -1;
      }
      if (new Date(a) > new Date(b)) {
        return 1;
      }
      return 0;
    } else {
      return 0;
    }
  }
  if (a && b) {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  } else {
    return 0;
  }
}

export default function calculatePts(px: number) {
  return px * 0.43;
}

//https://isotropic.co/how-to-format-a-date-as-dd-mm-yyyy-in-javascript/
export function format(inputDate: Date) {
  let date, month;
  const year = inputDate.getFullYear();

  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;

  date = date.toString().padStart(2, "0");

  month = month.toString().padStart(2, "0");

  return `${month}/${date}/${year}`;
}

export function scrollToError() {
  const element = document.querySelector(".error-message");
  if (element) {
    element?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }
}
//https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
export function isValidHttpUrl(string: string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

export async function preloadImage(img: string) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = img;
    image.onload = resolve;
    image.onerror = reject;
  });
}

export async function preloadImages(list: string[]) {
  return list.map(preloadImage);
}

export function setTokenToHeaders(token: string) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
/**
 * This function is used for getting images via our server as proxy, by default we aim to download images direcly from s3
 * @param path - relative path
 */
export function getImagePath(path?: string) {
  const apiUrl = import.meta.env.VITE_APP_API_URL + "/assets/";
  return apiUrl + path;
}

export function getAvatarUrl(path?: string | undefined) {
  return path ? path : emptyProfilePlaceholder;
}

/**
 * Check size of file that is tried to upload Check the size of the file that is being uploaded
 * @param fileDimension - max file dimension in bytes
 */
export function checkFileDimension(fileDimension: number) {
  return async (value: any) => {
    if (isImageFileType(value)) {
      const image = await uploadImage(value.dataUrl);

      return (
        !!value.file &&
        !!image.height &&
        !!image.width &&
        image.height <= fileDimension &&
        image.width <= fileDimension
      );
    }
    return true;
  };
}
/**
 *
 * @param cbs - array of cbs that pass to test method
 * @returns - yup schema
 */
export function mapTestRules<T>(
  cbs: {
    cb: yup.TestFunction<T>;
    id: string;
    message: string;
  }[]
): MixedSchema<any, Record<string, any>, any> {
  const result: MixedSchema<any, Record<string, any>, any> = yup.mixed();
  return cbs.reduce(
    (schema, { cb, id, message }) => schema.test(id, message, cb),
    result
  );
}

/**
 * Check size of file that is tried to upload Check the size of the file that is being uploaded
 * @param fileSize - max file size in bytes
 */
export function checkFileSize(fileSize: number) {
  return (value: any) => {
    if (isImageType(value)) {
      return !!value.file && value.file?.size <= fileSize;
    }
    return true;
  };
}

/**
 * Check the type of file that being uploaded
 * @param allowedTypes - allowed types in array
 */
export function checkFileType(allowedTypes: string[]) {
  return (value: ImageType) => {
    if (isImageFileType(value)) {
      return !!allowedTypes.find(compareTypes(value.file));
    }
    return true;
  };
}

/**
 * compare type from file or file extension with allowed types
 * @param file - file
 * @param allowedType - mapped string from allowed types array
 */
function compareTypes(file: File | undefined) {
  return (allowedType: string) => {
    if (!file) {
      return false;
    }
    if (allowedType === file.type) return true;
    const fileExtension = file.name.slice(file.name.lastIndexOf("."));
    if (fileExtension === allowedType) return true;
    return false;
  };
}
//
export function prepareMessages(prev: Message[] = [], items: Message[]) {
  //sort+reverse each time
  const register: { [key: string]: Message } = {};

  prev.forEach((item) => {
    //make sure that if messages repeats - leave only last one
    register[item.sid] = item;
  });
  items.forEach((item) => {
    register[item.sid] = item;
  });
  //
  const newValues = Object.values(register)
    .sort((a, b) => {
      //@ts-ignore
      if (a.dateCreated < b.dateCreated) {
        return -1;
      }
      //@ts-ignore
      if (a.dateCreated > b.dateCreated) {
        return 1;
      }
      return 0;
    })
    .reverse();
  return newValues;
}
//
interface IAddCurrentChatMessagesParams {
  prev: CurrentChatType;
  message: Message;
  id: string; //conversation id
}
export function updateCurrentChat(params: IAddCurrentChatMessagesParams) {
  if (!params.prev) {
    return params.prev;
  }
  if (!params.message) {
    return params.prev;
  }
  const foundConversation = params.prev?.conversations?.find(
    (i) => i.conversation.id !== params?.id
  );
  if (!foundConversation) {
    return params.prev;
  }
  const messages = prepareMessages(foundConversation.messages || [], [
    params.message,
  ]); //updated messages
  const copiedConversation = { ...foundConversation, messages };
  const conversations =
    params.prev?.conversations?.filter(
      (i) => i.conversation.id !== copiedConversation.conversation.id
    ) || [];
  conversations?.unshift(copiedConversation);

  return { ...params?.prev, conversations };
}
interface IAddMessagesParams {
  prev: TwilioConversationsContextValueType;
  messages: Message[];
  id: string; //conversation id
}
export function updateConversationMessages(
  params: IAddMessagesParams
): TwilioConversationsContextValueType {
  if (!params?.prev) {
    return null;
  }
  if (!params?.messages.length) {
    return params.prev;
  }
  const { chat, conversation } = findChatAndConversationByConversationId(
    params.id,
    params?.prev
  );

  if (!chat) {
    return params.prev;
  }

  //
  if (!conversation) {
    return params.prev;
  }
  const messages = prepareMessages(
    conversation?.messages || [],
    params.messages
  ); //updated messages
  const orderIndex = Object.values(params?.prev).sort((a, b) => {
    //TODO- CHANGE
    if (a.orderIndex < b.orderIndex) {
      return -1;
    }
    if (a.orderIndex > b.orderIndex) {
      return 1;
    }
    return 0;
  })[0]?.orderIndex;
  //

  const chats = {
    ...params.prev,
  };
  if (chat?.id) {
    chats[chat.id] = {
      ...chat,
      orderIndex,
      conversations: chat.conversations.map((i) => {
        if (i.conversation.id === params.id) {
          return {
            ...i,
            messages,
          };
        }
        return i;
      }),
    };
  }
  ////////////////////
  return chats;
}

interface IUpdatePaginatorInCurrentChatParams {
  prev: CurrentChatType;
  paginator: Paginator<Message>;
  id: string | undefined; //conversation id
}

export function updatePaginatorInCurrentChat(
  params: IUpdatePaginatorInCurrentChatParams
): CurrentChatType {
  if (!params?.prev) {
    return null;
  }
  if (!params?.paginator) {
    return params.prev;
  }
  if (!params?.id) {
    return params.prev;
  }
  const chat = params.prev;
  const conversation = chat?.conversations.find(
    (i) => i.conversation.id === params.id
  );

  if (!chat) {
    return params.prev;
  }
  if (!conversation) {
    return params.prev;
  }
  const messages = params.paginator.items;

  return {
    ...params.prev,
    conversations: chat.conversations.map((conversation) => {
      if (conversation.conversation.id === params.id) {
        return {
          ...conversation,
          paginator: params.paginator,
          messages: prepareMessages(conversation?.messages, messages), //messages.length ? messages : conversation.messages,
        };
      }
      return conversation;
    }),
  };
}
interface IUpdatePaginatorParams {
  prev: TwilioConversationsContextValueType;
  paginator: Paginator<Message>;
  id: string | undefined; //conversation id
}

export function updatePaginatorAndMessages(
  params: IUpdatePaginatorParams
): TwilioConversationsContextValueType {
  if (!params?.prev) {
    return null;
  }
  if (!params?.paginator) {
    return params.prev;
  }
  if (!params?.id) {
    return params.prev;
  }
  const { chat, conversation } = findChatAndConversationByConversationId(
    params.id,
    params?.prev
  );
  if (!chat) {
    return params.prev;
  }
  if (!conversation) {
    return params.prev;
  }
  const messages = params.paginator.items;

  const conversations = {
    ...params.prev,
    [chat.id]: {
      ...chat,
      conversations: chat.conversations.map((conversation) => {
        if (conversation.conversation.id === params.id) {
          return {
            ...conversation,
            paginator: params.paginator,
            messages: prepareMessages(conversation?.messages, messages), //messages.length ? messages : conversation.messages,
          };
        }
        return conversation;
      }),
    },
  };

  return conversations;
}

export function checkIfRed(
  participant: Partial<Participant> | undefined,
  messageIndex: number
) {
  let isViewed = false;

  if (participant) {
    const lastReadMessageIndex = participant.lastReadMessageIndex;
    if (typeof lastReadMessageIndex === "number") {
      isViewed = messageIndex <= lastReadMessageIndex; // if message index lower  - it means  read
    }
  }

  return isViewed;
}

export function checkIfMessageRead(
  participants: Partial<Participant>[] | null | undefined,
  user: string | undefined,
  messageIndex: number | undefined
) {
  let isViewed = true;
  if (participants?.length) {
    const myParticipant = participants.find(
      (part) => (part.attributes as JSONObject)?.user === user
    );
    if (typeof messageIndex === "number") {
      isViewed = checkIfRed(myParticipant, messageIndex);
    }
  }
  return isViewed;
}
//
export function updateParticipantOfCurrentChat(
  prev: CurrentChatType,
  updatedParticipant: Participant
) {
  if (!updatedParticipant) {
    return prev;
  }
  const cid = (updatedParticipant.attributes as JSONObject)
    ?.conversation as unknown as string; //id from db
  const foundConversation = prev?.conversations?.find(
    (i) => i.conversation.id !== cid // this is conversation id from twilio
  );
  if (!foundConversation) {
    return prev;
  }

  if (foundConversation && prev) {
    if (foundConversation) {
      const participants =
        foundConversation.participants?.filter(
          (i) => i.sid !== updatedParticipant.sid
        ) || [];
      if (participants?.length) {
        participants.push(updatedParticipant);
      }

      const result: CurrentChatType = {
        ...prev,
        conversations: prev.conversations.map((i) => {
          if (i.conversation.id === cid) {
            return {
              ...i,
              participants,
            };
          }
          return i;
        }),
      };
      return result;
    }
  }
  return prev;
}
///

export function updateParticipant(
  prev: TwilioConversationsContextValueType,
  updatedParticipant: Participant
) {
  if (!updatedParticipant) {
    return prev;
  }
  const cid = (updatedParticipant.attributes as JSONObject)
    ?.conversation as unknown as string; //id from db
  const { chat, conversation } = findChatAndConversationByConversationId(
    cid,
    prev
  );
  if (!chat) {
    return prev;
  }
  if (!conversation) {
    return prev;
  }
  if (conversation && prev) {
    if (conversation) {
      const participants =
        conversation.participants?.filter(
          (i) => i.sid !== updatedParticipant.sid
        ) || [];
      if (participants?.length) {
        participants.push(updatedParticipant);
      }
      const result: TwilioConversationsContextValueType = {
        ...prev,

        [chat.id]: {
          ...chat,
          conversations: chat.conversations.map((i) => {
            if (i.conversation.id === cid) {
              return {
                ...i,
                participants,
              };
            }
            return i;
          }),
        },
      };
      return result;
    }
  }
  return prev;
}
/////
export function getTypingTextIndicatorForUser(
  conversation: IFullConversation | null | undefined,
  conversations: TwilioConversationsContextValueType | null | undefined,
  user: string | undefined //current user
) {
  let currentConversation: null | ITwilioConversations = null;
  let someOtherParticipants: null | Participant[] | undefined = null;
  if (conversation && conversations && conversation.id) {
    const res = findChatAndConversationByConversationId(
      conversation.id,
      conversations
    );
    if (res.conversation) {
      currentConversation = res.conversation;
    }
  }

  if (currentConversation) {
    someOtherParticipants = currentConversation?.participants?.filter((i) => {
      const isOther = (i.attributes as JSONObject).user !== user;
      return isOther;
    });
  }
  //
  const names: string[] = [];
  someOtherParticipants?.forEach((participant) => {
    if (participant?.isTyping && conversation && currentConversation) {
      const found = conversation?.participants?.find(
        (i) => i.participant === participant?.sid
      );
      names.push(found?.user?.name || "Someone");
    }
  });

  if (names.length >= 2) {
    return names.join(" ") + ", are typing...";
  }
  if (names.length === 1) {
    return names.join(" ") + " is typing...";
  }
  return "";
}

//
export function transformRateToPercentages(rate: number | string | undefined) {
  if (typeof rate === "string" && rate) {
    return Math.round((parseFloat(rate) / 5) * 100);
  }
  if (typeof rate === "number" && !isNaN(rate)) {
    return Math.round((rate / 5) * 100);
  }
  return 0;
}
export function isUnviewedNotification(item: INotification<IUser> | undefined) {
  let thereIsUnviewedNotification = false;
  if (item) {
    if (!item?.viewed) {
      thereIsUnviewedNotification = true;
    }
  }
  return thereIsUnviewedNotification;
}
///
export function getStylesForReactSelectInputs(
  config: StylesConfig<ISelectOption> = {}
) {
  const customStyles: StylesConfig<ISelectOption> = {
    valueContainer: (provided) => {
      return { ...provided, padding: "0 0" };
    },
    control: (provided, state) => {
      const styles = {
        ...provided,
        borderColor: "#CCCCCC",
        minHeight: "fit-content",
      };

      if (state.isFocused) {
        styles.borderColor = "#78ade4";
        styles.boxShadow =
          "0 0 5px 0 rgb(120 173 228 / 76%), inset 0 2px 2px 0 rgb(0 0 0 / 7%);";
      }

      return styles;
    },
    placeholder: (provided) => {
      return { ...provided, fontSize: "14px", color: "#9da4b1" };
    },
    singleValue: (provided) => {
      return {
        ...provided,
        color: "black",
        lineHeight: "17px",
        fontSize: "16px",
        fontWeight: "400",
        marginLeft: "0",
        marginRight: "0",
        paddingLeft: "16px",
      };
    },
    input: (provided) => {
      return {
        ...provided,
        color: "#333333",
        lineHeight: "17px",
        fontSize: "14px",
        fontWeight: "400",
      };
    },
    menu: (provided) => {
      return { ...provided, borderRadius: "3px" };
    },
    indicatorsContainer: (provided) => {
      return { ...provided, transform: "scale(0.7)" };
    },
    indicatorSeparator: () => ({}),
    menuList: (provided) => {
      return { ...provided, paddingTop: "8px", paddingBottom: "8px" };
    },
    option: (provided, state) => {
      if (state.isSelected) {
        provided.backgroundColor = "#dfe3eb"; //look at calmIridescence color in tailwind config
        provided.color = "inherit";
      }

      return {
        ...provided,
        color: "#333333",
        padding: "2px 12px",
        fontSize: "14px",
        fontWeight: "400",
      };
    },
    ...config,
  };
  return customStyles;
}
export function removeRepeatedSlashes(path: string) {
  return path.replace(/\/+/g, "/");
}

export function getMostImportantProfileNotification(
  notifications: ProfileNotificationType[] | undefined
) {
  const mostImportantNotification = notifications?.reduce((prev, curr) => {
    if ((prev?.priority || 0) > (curr?.priority || 0)) {
      return prev;
    }
    return curr;
  }, null);
  return mostImportantNotification;
}
//
export function allowAddDebitCardOrBankAccount(
  account: IAccount["stripeAccount"] | undefined
) {
  // if there left add only external account and stripe bank account information will be completed
  return (
    account?.details_submitted ||
    (account?.requirements.currently_due.length === 1 &&
      account?.requirements.currently_due[0] === "external_account")
  );
}
interface ICheckIfLastPaginatedItemParams<T = any> {
  data: InfiniteData<IAxiosResponseWithPagination<T>>;
  pageKey: number;
  itemKey: number;
  page: IAxiosResponseWithPagination<T>;
}
export function checkIfIsLastPaginatedItem<T = any>({
  data,
  pageKey,
  itemKey,
  page,
}: ICheckIfLastPaginatedItemParams<T>) {
  const lastPage = data.pages.length - 1 === pageKey;
  const lastItem = page.data.items.length - 1 === itemKey;
  const last = lastItem && lastPage;
  return last;
}
export function checkIfDevVersion() {
  const host = import.meta.env.VITE_APP_HOST;
  return (
    import.meta.env.VITE_APP_DEV_HOST === host ||
    window.location.hostname === "localhost"
  );
}

export function getSocialId(
  user: Partial<IUser<any, ISocialNetwork<any>, any, any, any>> | undefined,
  type = "instagram"
) {
  const socialId = user?.socialNetworks?.find((i) => i.type === type)?.socialId;
  return socialId;
}

export function classnames(...args: classNames.ArgumentArray): string {
  if (args.length === 1 && typeof args[0] === "string") {
    // to optimize performance
    return args[0];
  }
  return twMerge(classNames(...args));
}

export function getDirection({
  isAdminChat,
  checkedInterlocutor,
  user,
  isMine,
}: {
  isAdminChat: boolean;
  checkedInterlocutor: string;
  user: string;
  isMine: boolean;
}) {
  let direction: "left" | "right" = "right";
  if (isAdminChat && user === checkedInterlocutor) {
    direction = "left";
  }
  if (!isAdminChat && isMine) {
    direction = "left";
  }
  return direction;
}

export function getReferralLink(code: string, usernameOrId?: string) {
  return `${import.meta.env.VITE_APP_HOST}/${
    usernameOrId ? usernameOrId : ""
  }?referral=${code}`;
}

export function checkIfCampaignIsExpired(
  program: IReferralProgram,
  user: IUser
) {
  if (program.activeMinutes && user.createdAt) {
    //add minutes to createdAt of campaign to calculate if it is expired
    const createdAt = new Date(user.createdAt);
    const expiredAt = new Date(
      createdAt.getTime() + program.activeMinutes * 60000
    );
    if (expiredAt < new Date()) {
      return false; //
    }
  }
  return true;
}
export function checkIfReferralProgramIsStarted(
  program: IReferralProgram | null | undefined
) {
  return program?.startAt && new Date() >= new Date(program.startAt);
}

export function checkIfReferralProgramIsExpired(
  program: IReferralProgram | null | undefined
) {
  const isStarted = checkIfReferralProgramIsStarted(program);

  let isExpired = false; // if ignoreExpiredAt - program will not expire
  if (!program?.ignoreExpiredAt) {
    if (isStarted && program?.expiredAt) {
      isExpired = new Date(program.expiredAt) <= new Date();
    }
  }
  return isExpired;
}

export function getFutureHumanReadableDateFromMinutes(minutes: number) {
  const now = new Date();
  const future = new Date(now.getTime() + minutes * 60000);
  return future.toLocaleDateString("en-US", {
    month: "short",
    year: "numeric",
    day: "2-digit",
  });
}

export function transformMinutesIntoDays(minutes: number | null | undefined) {
  if (!minutes) {
    return 0;
  }
  const days = Math.ceil(minutes / 60 / 24);
  return days;
}

export function getUserName(
  user: Partial<IUser<any, any, any, any, any>> | undefined | string
) {
  if (typeof user === "string") {
    return user || "Unknown";
  }
  let name = user?.name;
  const socialId = getSocialId(user);
  if (!name && socialId) {
    name = socialId;
  }
  if (!name && user?.email) {
    name = user?.email;
  }
  return name || "Unknown";
}
