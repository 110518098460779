import ReactDOM from "react-dom/client";
import { initializeApp } from "firebase/app";
import { QueryClient } from "@tanstack/react-query";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { hotjar } from "react-hotjar";
import {
  init,
  browserTracingIntegration,
  replayIntegration,
} from "@sentry/react";

import reportWebVitals from "./reportWebVitals";
import "./assets";
import { checkIfDevVersion } from "utils/common";
import { Render } from "MainComponents";
//const Sentry = lazy(() => import("@sentry/react"));

// import ReloadPrompt from "layouts/MainLayout/ReloadPrompt";

hotjar.initialize(3632767, 6);

// make imports in code splitting version to be lazy

init({
  enabled: !!import.meta.env.PROD,
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  environment: checkIfDevVersion() ? "development" : "production",

  integrations: [
    browserTracingIntegration(),
    replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", import.meta.env.VITE_APP_HOST],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

TimeAgo.addDefaultLocale(en);

export const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET,
};

initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

//https://www.codemzy.com/blog/react-query-cachetime-staletime
//@ts-ignore
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 3, // 10 s
      cacheTime: 1000 * 4, // 15 s
    },
  },
});

if (root?.render) {
  root?.render(<Render />);
}

// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
