export enum APP_ROUTES {
  USER = "/",
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  HOME = "/",
  HELP = "/help",
  FORGOT_PASSWORD = "/forgot-password",
  CHANGE_PASSWORD = "/change-password",
  ME = "/me",
  EDIT = "/edit",
  ACTIONS = "/actions",
  CUSTOM_TOKEN_AUTH = "/custom-token-auth",
  PRIVACY_POLICY = "/privacy-policy",
  TERMS_AND_CONDITIONS = "/terms-and-conditions",
  PAYMENT = "/payment",
  PAYMENT_STATUS = "/payment-status",
  HISTORY = "/history",
  CHATS = "/chats",
  MY_REVIEWS = "/my-reviews",
  NOTIFICATIONS = "/notifications",
  ADMIN_USERS = "/admin/users",
  ADMIN = "/admin",
  ADMIN_CHATS = "/admin/chats",
  ADMIN_APPLICATIONS = "/admin/applications",
  ADMIN_CASES = "/admin/cases",
  ADMIN_REFERRALS = "/admin/referrals",
  ABOUT = "/about",
  FAQ = "/faq",
  NOT_FOUND = "/404",
  PAYMENT_ACCOUNT = "/payment-account",
  REFERRAL_PROGRAMS = "/referral-programs",
  REFERRAL_CAMPAIGNS = "/referral-campaigns",
  REFERRALS = "/referrals",
  AFFILIATE = "/affiliate",
  HOW_IT_WORKS = "/how-it-works",
  AFFILIATE_DETAILS = "/affiliate-details",
}
//http://localhost:3000/change-password?oobCode=IjNljfi0wqmGi1wqXVpnA1YDZVBIh25k5_cbFlr_tqgAAAGCxbdzIQ
