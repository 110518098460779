import { Button } from "./Button/Button";

export function ErrorFallback({
  error,
  eventId,
  button = (
    <Button
      mode="primary"
      onClick={() => {
        window.location.reload();
        //resetError();
      }}
    >
      Reload
    </Button>
  ),
}: {
  error: Error;
  resetErrorBoundary?: () => void;
  onReset?: () => void;
  eventId?: string;
  button?: React.ReactNode;
}) {
  return (
    <div className="flex-1 h-full flex flex-col justify-center items-center">
      <div className="w-full justify-center flex">
        <div
          role="alert"
          className="flex flex-col items-center justify-center h-full flex-1 gap-4 relative max-w-[574px] "
        >
          <div className=" text-black font-semibold">Something went wrong:</div>
          <span className="w-full flex flex-wrap text-center justify-center">
            {error.message}
          </span>
          {eventId && <span className=" text-sm">Error id: {eventId}</span>}
          {button}
        </div>
      </div>
    </div>
  );
}

export function ReactErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error?: any;
  resetErrorBoundary?: () => void;
}) {
  return (
    <ErrorFallback
      error={error}
      resetErrorBoundary={resetErrorBoundary}
      eventId="Error catch by React Error Boundary"
    />
  );
}
