import { lazy } from "utils/react-lazy-with-reload";

export const App = lazy(() => import("./App"));
export const StripeContextProvider = lazy(() =>
  import("context").then((module: any) => ({
    default: module.StripeContextProvider,
  }))
);
export const QueryClientProvider = lazy(() =>
  import("@tanstack/react-query").then((module: any) => ({
    default: module.QueryClientProvider,
  }))
);
export const BrowserRouter = lazy(() =>
  import("react-router-dom").then((module: any) => ({
    default: module.BrowserRouter,
  }))
);
export const RecoilRoot = lazy(() =>
  import("recoil").then((module: any) => ({ default: module.RecoilRoot }))
);
