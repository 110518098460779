import { classnames } from "utils/common";
import "./Loader.css";
import { ReactComponent as SpinnerIcon } from "assets/img/spinner.svg";

export interface ILoaderProps {
  className?: string;
  classNames?: { spinner?: string; dots?: string };
  type?: "dots" | "spinner";
}
//https://codepen.io/brainbrian/pen/bwKBJj
export function Loader({ className, classNames, type = "dots" }: ILoaderProps) {
  return (
    <div
      className={classnames(
        "flex items-center justify-center h-full",
        className
      )}
    >
      {type === "dots" && (
        <div
          className={classnames(
            "loading-dots flex gap-1 justify-center items-center",
            classNames?.spinner
          )}
        >
          <div
            className={classnames(
              "dot-wrp  bg-white  h-[10px] w-[10px] rounded-[10px] relative shrink-0",
              classNames?.dots
            )}
          >
            <div className="loading-dots--dot absolute top-0 left-0  " />
          </div>
          <div
            className={classnames(
              "dot-wrp  bg-white  h-[10px] w-[10px] rounded-[10px] relative shrink-0",
              classNames?.dots
            )}
          >
            <div className="loading-dots--dot absolute top-0 left-0  " />
          </div>
          <div
            className={classnames(
              "dot-wrp  bg-white  h-[10px] w-[10px] rounded-[10px] relative shrink-0",
              classNames?.dots
            )}
          >
            <div className="loading-dots--dot absolute top-0 left-0  " />
          </div>
        </div>
      )}
      {type === "spinner" && (
        <SpinnerIcon className={classnames(" ", classNames?.spinner)} />
      )}
    </div>
  );
}
