import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { QueryParamProvider } from "use-query-params";

import { Offline } from "react-detect-offline";
import ReloadPrompt from "layouts/MainLayout/ReloadPrompt";
import { ErrorBoundary as SErrorBoundary } from "@sentry/react";
import { ProfileNotification } from "components/ProfileNotification/ProfileNotification";
import { ErrorBoundary } from "react-error-boundary";
import { SuspenseWrp } from "components/SuspenceWrp";
import { ErrorFallback, ReactErrorFallback } from "components/ErrorFallback";
import { Loader } from "components/Loader/Loader";

import {
  App,
  BrowserRouter,
  QueryClientProvider,
  RecoilRoot,
} from "LazyComponents";
import { queryClient } from "index";
import { StripeContextProvider } from "context/StripeContext";

export function SentryErrorBoundary({ FallbackComponent, children }: any) {
  return (
    <SErrorBoundary fallback={FallbackComponent}>{children}</SErrorBoundary>
  );
}

export function Render() {
  return (
    <SuspenseWrp
      fallback={
        <div className="w-full flex justify-center">
          <Loader className="w-[50px] h-[50px]" />
        </div>
      }
    >
      {/* use error boundary and sentry error boundary to test, cause we get weird an error - UnhandledRejection: Non-Error promise rejection captured with value: Object Not Found Matching Id:2, MethodName:update, ParamCount:4
          we assume that this connected with sentry error boundary, we assume that second error boundary will catch this error
      */}
      <ErrorBoundary FallbackComponent={ReactErrorFallback}>
        <SentryErrorBoundary FallbackComponent={ErrorFallback}>
          <QueryClientProvider client={queryClient}>
            <StripeContextProvider>
              <BrowserRouter>
                <QueryParamProvider adapter={ReactRouter6Adapter}>
                  <RecoilRoot>
                    <App />
                  </RecoilRoot>
                </QueryParamProvider>
              </BrowserRouter>
            </StripeContextProvider>
          </QueryClientProvider>
        </SentryErrorBoundary>
      </ErrorBoundary>
      {import.meta.env.PROD && <ReloadPrompt />}
      <Offline>
        <ProfileNotification
          type="warning"
          showCloseButton={false}
          className="fixed right-0 left-0 bottom-0"
        >
          <div className="flex w-full gap-4  ">
            <div className="text-sm">
              Your application is offline. Please check your internet connection
            </div>
          </div>
        </ProfileNotification>
      </Offline>
    </SuspenseWrp>
  );
}
