import type { ReactNodeArray } from "prop-types";
import React, { ReactNode, useMemo, useState } from "react";

import type { Stripe } from "@stripe/stripe-js";
export const StripeContext = React.createContext<{
  isLoading: boolean;
  context: null | any;
  setContext?: React.Dispatch<React.SetStateAction<any | null>>;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  isLoading: false,
  context: null,
});

export function StripeContextProvider({
  // should be in twilio client context
  children,
}: {
  children: ReactNode | ReactNodeArray;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [context, setContext] = useState<null | Stripe>(null);
  //remove stripe from on logout
  return (
    <StripeContext.Provider
      value={useMemo(
        () => ({ context, setContext, isLoading, setIsLoading }),
        [context, isLoading]
      )}
    >
      {children}
    </StripeContext.Provider>
  );
}
